@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.background-filtering {
  background-image: url("../public/assets/background2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes hitted-animation {
  0% {
    width: 34vw;
  }

  50% {
    width: 36vw;
  }

  100% {
    width: 34vw;
  }
}

.hitted {
  animation-name: hitted-animation;
  animation-duration: 0.15s;
  animation-timing-function: ease-in-out;
}

.red-filter {
  filter: hue-rotate(0deg) saturate(200%);
}

/* 빨간색 강조 */
.red-filter {
  filter: hue-rotate(-90deg) saturate(300%);
}

@keyframes hitted-text-animation {
  0% {
    font-size: 6rem;
  }

  50% {
    font-size: 10rem;
  }

  100% {
    font-size: 6rem;
  }
}

.hitted-text {
  animation-name: hitted-text-animation;
  animation-duration: 0.15s;
  animation-timing-function: ease-in-out;
}
